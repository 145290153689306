/* @import url("./styles/fonts/font.css"); */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  * {
    font-family: "Helvetica Neue" !important;
  }

  html,
  body {
    font-size: 14px;
    color: black;
    overflow-x: hidden;
  }

  input,
  textarea,
  select,
  button {
    font-family: inherit;
  }
}

body {
  margin: 0;
  font-family: "Helvetica Neue";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Custom Scrollbar */
.custom-scrollbar::-webkit-scrollbar {
  height: 8px; /* Scrollbar height */
  width: 5px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #00000026; /* Scrollbar color */
  border-radius: 10px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #f0f0f0; /* Track color */
}

@keyframes textLoading {
  0% {
    background-position: 100% 0;
  }
  100% {
    background-position: -100% 0;
  }
}

.loading-text {
  background: linear-gradient(to right, black 0%, rgba(0, 0, 0, 0.3) 100%);
  background-size: 200% 100%;
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  animation: textLoading 5s linear infinite;
}

.custom-tooltip .ant-tooltip-inner {
  background-color: white !important;
  color: black !important;
}

.custom-tooltip .ant-tooltip-arrow::before {
  background-color: white !important;
}

@media print {
  .no-print {
    display: none !important;
  }
}
